import Vue from 'vue'
import ability from '@/plugins/acl/ability'
import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'
import jwtDefaultConfig from './modules/auth/jwtDefaultConfig'

// Service

// Circular reference
// import router from '@/router'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
// const API_BASE = '' // @fake-db
// const API_BASE = 'http://localhost:8060/api'
const API_BASE = process.env.VUE_APP_API_BASE
const XDEBUG = process.env.VUE_APP_XDEBUG || false // X-DEBUG indicator

export default class BaseApiService {
  // Base URL depending .ENV
  baseUrl = API_BASE

  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    // console.log('APIBASE', this.axiosIns)
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Checking... Se ha marcado como no requiere Autorizacion
        // config.noAuthorization: boolean is a custom param for NO AUTHORIZATION request
        if (!config.noAuthorization) {
          Object.assign(config, this.getAuthConfig())
        }

        // Object.assign(config.headers, { 'Access-Control-Allow-Origin': '*' })

        // Add x-debug param
        if (XDEBUG) {
          if (!config.params) {
            Object.assign(config, { params: {} })
          }
          Object.assign(config.params, { XDEBUG_SESSION_START: 'PHPSTORM' })
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            //   if (response.config && response.config.url.includes('/auth/access-token')) {
            this.logout().then(() => {
              window.location.href = '/login'

              // router.push({ name: 'auth-login' })
              // return true
            })
          } else {
            this.isAlreadyFetchingAccessToken = true

            // useJwt
            this.accessToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false
                this.onAccessTokenFetched(r.data.accessToken)
              })
              .catch(e => {
                // eslint-disable-next-line no-console
                console.log(e)
                this.isAlreadyFetchingAccessToken = false
              })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })

          return retryOriginalRequest
        }

        // Error
        if (response) {
          // TODO - Temporal => Por ahora sacamos el error
          // Errores de dominio se resuelven en cada formulario
          if (error.response && error.response.data && error.response.data.error) {
            let errorMessage = ''
            Object.keys(error.response.data.error).forEach(key => {
              let errorArr = error.response.data.error[key]
              if (errorArr) {
                errorArr = typeof errorArr === 'string' ? [errorArr] : errorArr

                // eslint-disable-next-line prefer-destructuring
                errorMessage += `${key}: ${errorArr[0]}`
              }
            })
            errorMessage = errorMessage || 'Error de dominio'

            // store.commit('app/ERROR', `(${error.response.status}) ${errorMessage}`)
            Vue.$toast.error(`(${error.response.status}) ${errorMessage}`, { position: 'bottom-center' })
          } else {
            // store.commit('app/ERROR', `(${error.response.status}) ${error.message}`)
            Vue.$toast.error(`(${error.response.status}) ${error.message}`, { position: 'top-center' })
          }
        } else {
          Vue.$toast.error(error.message, { position: 'top-center' })
        }

        return Promise.reject(error)
      },
    )
  }

  // // Get token from localStorage
  // const accessToken = this.getToken()

  //   // If token is present add it to request's Authorization Header
  //   if (accessToken) {
  //     // eslint-disable-next-line no-param-reassign
  //     config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
  //   }
  getAuthConfig() {
    const headers = {}

    // Get token from localStorage
    const accessToken = this.getToken()
    if (accessToken) {
      headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
    }

    return {
      mode: 'cors',
      headers,
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  /** LOCAL STORAGE: Use here and in AuthService */
  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    if (value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    } else {
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    }
  }

  setRememberMe = (remember, username, password) => {
    if (remember) {
      localStorage.setItem('remember', remember)
      localStorage.setItem('username', username)
      localStorage.setItem('password', password)
    } else {
      localStorage.removeItem('remember')
      localStorage.removeItem('username')
      localStorage.removeItem('password')
    }
  }

  // TODO - Eliminar, el que lo quiera usar que lo implemente o use useAuth o lo que sea
  // Implementamos aquí porque puede ser usado por cualquier módulo
  // Igual que el LOGIN
  accessToken() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(
          this.baseUrl + this.jwtConfig.refreshEndpoint,
          { accessToken: this.getToken() },

          //   { noAuthorization: true },
        )
        .then(response => {
          const { userData } = response.data
          if (userData) {
            // >>> login actions
            this.setToken(response.data.accessToken)
            ability.update(userData.ability)
            store.commit('auth/SUCCESS', response.data)
            store.dispatch('user/SET', userData)

            // <<<

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // TODO - Eliminar, el que lo quiera usar que lo implemente o lo instancie
  // Implementamos aquí porque puede ser usado por cualquier módulo
  logout() {
    // return this.axiosIns.post(API_BASE + this.jwtConfig.logoutEndpoint)
    return new Promise(resolve => {
      this.setToken(null)

      ability.update(initialAbility) // Permisos por defecto
      store.commit('auth/LOGOUT')
      store.dispatch('user/SET', null)

      resolve()
    })
  }
}
