import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
// import '@core/scss/vue/libs/toastification.scss'
import 'vue-toastification/dist/index.css'

const options = {
  // You can set your default options here
  position: 'top-right',
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 2000,
  transition: 'Vue-Toastification__fade',
}

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, options)
