// Ejemplos de uso
//  Leer contactRequestsPendingCount => getters      => store.getters['user/contactRequestsPendingCount']
//  Leer userData                    => directamente => store.state.user.userData
//  Leer company info                => directamente => store.state.user.userData.company
//  Leer seller/purchaser            => directamente => store.state.user.seller / store.state.user.purchaser
const initialState = {
  userData: null,

  // AppMode (SwitchSellerPurchaser available when both)
  seller: false,
  purchaser: false,
  appMode: undefined,

  // Company Role (Admin/staff) => Se usará continuamente para permisos de ruta
  companyRole: 'staff',
}

// AppMode
const getDefaultAppMode = (isSeller, isPurchaser) => {
  const appModesPermit = []
  if (isSeller) {
    appModesPermit.push('seller')
  }
  if (isPurchaser) {
    appModesPermit.push('purchaser')
  }

  // Por defecto usamos el de memoria o seller
  const currentAppMode = localStorage.getItem('app-mode')
  if (appModesPermit.includes(currentAppMode)) {
    return currentAppMode
  }

  return appModesPermit[0] || undefined // se podría indicar seller
}

export default {
  namespaced: true,
  state: {
    userData: null,

    // AppMode (SwitchSellerPurchaser available when both)
    seller: false,
    purchaser: false,
    appMode: undefined, // seller || purchaser || undefined

    // Company Role (Admin/staff) => Se usará continuamente para permisos de ruta
    companyRole: 'staff',

    // >>> SFR 25.01.2022 - Table Visibility
    //  ProductDemander: true
    //  ProductSupplier: true
    //  MarketplaceCatalog: false
    //  isTableVisibleContact: true
    isTableVisibleProductDemander: localStorage.getItem('is-table-visible-product-demander')
      ? localStorage.getItem('is-table-visible-product-demander') === 'true'
      : true,
    isTableVisibleProductSupplier: localStorage.getItem('is-table-visible-product-supplier')
      ? localStorage.getItem('is-table-visible-product-supplier') === 'true'
      : true,
    isTableVisibleMarketplaceCatalog: localStorage.getItem('is-table-visible-marketplace-catalog')
      ? localStorage.getItem('is-table-visible-marketplace-catalog') === 'true'
      : false,
    isTableVisibleContact: localStorage.getItem('is-table-visible-contact')
      ? localStorage.getItem('is-table-visible-contact') === 'true'
      : true,
  },
  getters: {
    authFullName: state => (state.userData !== null ? state.userData.fullName : ''),
    isLoggedIn: state => !!state.userData,
    getBookmarks: state => {
      if (state.userData && state.userData.extras && state.userData.extras.shortcuts) {
        return state.userData.extras.shortcuts
      }

      return []
    },

    // Company info
    isAppModeSeller: state => state.seller === true,
    isAppModePurchaser: state => state.purchaser === true,

    // Metas -> Alias
    productSupplierFavoritesPendingCount: state => state.userData?.metas?.productSupplierFavoritesPendingCount || 0,
    contactRequestsPendingCount: state => state.userData?.metas?.contactRequestsPendingCount || 0,
    contactRequestInboundsPendingCount: state => state.userData?.metas?.contactRequestInboundsPendingCount || 0,
    campaignsPendingCount: state => state.userData?.metas?.campaignsPendingCount || 0,
    campaignInboundsPendingCount: state => state.userData?.metas?.campaignInboundsPendingCount || 0,
    chatRoomUsersPendingCount: state => state.userData?.metas?.chatRoomUsersPendingCount || 0,
  },

  // store.commit('user/SET', payload)
  mutations: {
    SET(state, payload) {
      state.userData = payload

      // Reset all
      if (payload === null) {
        Object.assign(state, initialState)

        // Set user data
      } else {
        // localStorege: Change language => Available when app start
        if (payload.language) {
          localStorage.setItem('language', payload.language)
        }

        // AppMode
        // if (payload.company && payload.company.general) {
        state.seller = payload?.seller || false
        state.purchaser = payload?.purchaser || false

        // state.appMode = getDefaultAppMode(state.seller, state.purchaser)

        // Company role
        state.companyRole = payload?.companyRole || 'staff'
      }
    },

    // Not in use
    SHORTCUTS(state, userDataShortCuts) {
      if (state.userData && state.userData.extras && state.userData.extras.shortcuts) {
        state.userData.extras.shortcuts = userDataShortCuts
      }
    },

    // Not in use
    ACCOUNT(state, userDataAccount) {
      if (state.userData) {
        Object.assign(state.userData.account, userDataAccount) // GENERAL, INFO, NOTIFICATION, SOCIAL
      }
    },

    // Update Chat
    // Metas
    //  UPDATE_PRODUCT_SUPPLIER_FAVORITES_PENDING_COUNT     Marketplace - Favorites
    //  UPDATE_CONTACT_REQUESTS_PENDING_COUNT               Marketplace - Contact Requests          (PUBLISHED)
    //  UPDATE_CONTACT_REQUEST_INBOUNDS_PENDING_COUNT       Networking  - Contact Requests Inbounds (RECEIVED)
    //  UPDATE_CAMPAIGNS_PENDING_COUNT                      Marketing   - Campaigns                 (PUBLISHED)
    //  UPDATE_CAMPAIGN_INBOUNDS_PENDING_COUNT              Networking  - Campaign Inbounds         (RECEIVED)
    //  UPDATE_CHAT_ROOM_USERS_PENDING_COUNT                Chat        - Not Read
    UPDATE_PRODUCT_SUPPLIER_FAVORITES_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.productSupplierFavoritesPendingCount = count
      }
    },
    UPDATE_CONTACT_REQUESTS_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.contactRequestsPendingCount = count
      }
    },
    UPDATE_CONTACT_REQUEST_INBOUNDS_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.contactRequestInboundsPendingCount = count
      }
    },
    UPDATE_CAMPAIGNS_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.campaignsPendingCount = count
      }
    },
    UPDATE_CAMPAIGN_INBOUNDS_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.campaignInboundsPendingCount = count
      }
    },
    UPDATE_CHAT_ROOM_USERS_PENDING_COUNT(state, count) {
      if (state.userData && state.userData.metas) {
        state.userData.metas.chatRoomUsersPendingCount = count
      }
    },

    // Custom updates
    // El usuario ha cambiado el estado
    CHANGE_STATUS(state, newStatus) {
      if (state.userData) {
        state.userData.status = newStatus
      }
    },

    // Not in use (Por el momento) CHANGE_ROLE, CHANGE_SELLER, CHANGE_PURCHASER
    //  todo - Hay que resolver el tema de permisos, no solo vale cambiar state.companyRole
    // El usuario ha cambiado de role
    CHANGE_ROLE(state, newValue) {
      state.companyRole = newValue || 'staff'
    },

    // El usuario ha cambiado los perfiles
    CHANGE_SELLER(state, newValue) {
      state.seller = newValue || false
    },
    CHANGE_PURCHASER(state, newValue) {
      state.purchaser = newValue || false
    },
    CHANGE_APP_MODE(state, newValue) {
      state.appMode = newValue

      // TODO - No funciona el setAttribute
      // Change Theme color: `${newValue === 'seller' ? '#1c6bd1' : '#28c76f'}`
      if (document.querySelector('meta[name="theme-color"]')) {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute('content', `${newValue === 'seller' ? 'blue' : 'green'}`)
      }
    },

    // Table Visibility
    UPDATE_TABLE_VISIBILITY_PRODUCT_DEMANDER(state, value) {
      state.isTableVisibleProductDemander = value
    },
    UPDATE_TABLE_VISIBILITY_PRODUCT_SUPPLIER(state, value) {
      state.isTableVisibleProductSupplier = value
    },
    UPDATE_TABLE_VISIBILITY_MARKETPLACE_CATALOG(state, value) {
      state.isTableVisibleMarketplaceCatalog = value
    },
    UPDATE_TABLE_VISIBILITY_CONTACT(state, value) {
      state.isTableVisibleContact = value
    },
  },

  // store.dispatch('user/SET', userData)
  actions: {
    // Login or Logout
    SET({ commit, rootState }, payload) {
      //   const { userData } = rootState['auth/userData']z
      commit('SET', payload)

      // Set Default AppMode for this user
      // const currentStateUser = rootState.user
      // const currentStateAppMode = rootState.appConfig.layout.appMode
      // commit('appConfig/UPDATE_APP_MODE', getAppModeByUser(currentStateUser, currentStateAppMode), { root: true })
      // const currentStateUser = rootState.user
      // const currentStateAppMode = rootState.user.appMode || localStorage.getItem('app-mode')
      // commit('CHANGE_APP_MODE', getAppModeByUser(currentStateUser, currentStateAppMode))
      commit('CHANGE_APP_MODE', rootState.user.appMode || getDefaultAppMode(rootState.user.seller, rootState.user.purchaser))

      // Cargamos info de la empresa desde el usuario
      commit('company/SET', payload?.company, { root: true })

      //   // Set User Extra dependencies (Initialization)
      // const wishItemsCount = (payload && payload.extras && payload.extras.wishItemsCount) || 0
      // const sentItemsCount = (payload && payload.extras && payload.extras.sentItemsCount) || 0
      // const receivedItemsCount = (payload && payload.extras && payload.extras.receivedItemsCount) || 0
      // const messageItemsCount = (payload && payload.extras && payload.extras.messageItemsCount) || 0

      // commit('marketplace/UPDATE_WISH_ITEMS_COUNT', wishItemsCount, { root: true })
      // commit('network/UPDATE_SENT_ITEMS_COUNT', sentItemsCount, { root: true })
      // commit('network/UPDATE_RECEIVED_ITEMS_COUNT', receivedItemsCount, { root: true })
      // commit('chat/UPDATE_MESSAGE_ITEMS_COUNT', messageItemsCount, { root: true })
    },
  },
}
