<template>
  <v-app>
    <!-- TODO: All elevations are to bottom and footer requires shadow on top -->
    <!-- :class="[{ 'px-5 app-bar-shinked': true }, { 'bg-blur': true }]" -->
    <!-- <v-app-bar
      ref="refAppBar"
      app
      :absolute="true"
      flat
      :elevate-on-scroll="false"
      :elevation="3"
      class="mx-auto-kk app-bar-static-kk"
    >
      <slot name="navbar"></slot>
    </v-app-bar> -->
    <slot name="navbar"></slot>
    <slot name="navbarTab"></slot>
    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style>
</style>
