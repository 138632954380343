<template>
  <LayoutNoAuth>
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ }">
      <AppBarNoAuth />
    </template>
  </LayoutNoAuth>
</template>

<script>

// Core components
import LayoutNoAuth from '@/@core/layouts/variants/blank/LayoutNoAuth.vue'

// Components
import AppBarNoAuth from '@/components/AppBar/AppBarNoAuth.vue'

export default {
  components: {
    LayoutNoAuth,
    AppBarNoAuth,
  },
}
</script>
