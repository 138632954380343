<template>
  <layout-blank>
    <slot></slot>
    <template #navbar>
      <AppBarNoAuth />
    </template>
  </layout-blank>
</template>

<script>
import { mdiMenu, mdiHeartOutline } from '@mdi/js'

// Components
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlankCopy.vue'

// Components
import AppBarNoAuth from '@/components/AppBar/AppBarNoAuth.vue'

export default {
  components: {
    LayoutBlank,
    AppBarNoAuth,
  },
  setup() {
    return {
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .navbar-content-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }
</style>
