import { getCurrentInstance } from '@vue/composition-api'

/**
 * Returns translated string if i18n package is available to Vue
 * If i18n is not configured then it will simply return what is being passed
 * Useful if you don't know if i18n is configured or not
 * Used in @core files to handle absence of i18n without errors
 * @param {String} key i18n key to use for translation
 */
export const t = key => {
  const vm = getCurrentInstance().proxy

  return vm.$i18n && vm.$t ? vm.$t(key) : key
}

// Exportamos la funcion Te = Existe translate (Returns true or false)
export const te = key => {
  const vm = getCurrentInstance().proxy

  return vm.$te ? vm.$te(key) : false
}

// Exportamos todo el módulo i18n para usar con composition-api: seput()
export const useI18n = () => {
  const vm = getCurrentInstance().proxy

  return {
    // eslint-disable-next-line no-underscore-dangle
    i18n: vm._i18n,
  }
}

export const _ = null
