// Notification
// https://www.npmjs.com/package/vue-toastification#composition-api-registration
import { mdiShield } from '@mdi/js'
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

export default function useChatHandler() {
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendAuthNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // https://www.npmjs.com/package/vue-toastification#render-a-component-with-props-and-events
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `Auth - ${title}`,
          variant,
          icon,

          // text: `You have successfully logged in as ${userData.companyRole}. Now you can start to explore!`,
        },
      },
      {
        position: POSITION.BOTTOM_CENTER,
        type: variant,
      },
    )
  }

  return {
    // UI
    sendAuthNotification,
  }
}
