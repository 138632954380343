import { computed, watch } from '@vue/composition-api'

// Theme switch (Seller / Purchaser)
import themeConfig from '@themeConfig'
import useAppConfig from '@core/@app-config/useAppConfig'

import store from '@/store'

export default function usAppConfigUser() {
  // Colors
  const sellerColor = '#1c6bd1' // Blue
  const purchaserColor = '#28c76f' // Green

  const sellerVariant = 'primary'
  const purchaserVariant = 'success'

  // https://vuetifyjs.com/en/styles/colors/#material-colors
  const sellerVariantLight = 'blue' // 'light-primary'
  const purchaserVariantLight = 'green' // 'light-success'

  // TODO - eliminar sellerCollor, purchaserColor and sellerVariant, purchaserVariant
  // Theme switch (Seller / Purchaser)
  const { themes, isDark } = useAppConfig()

  // >>> SFR 20.05.2020 - Seller / Purchaser integration
  // ------------------------------------------------
  // appMode: Seller / Purchaser
  // ------------------------------------------------
  const appMode = computed({
    get: () => store.state.user.appMode,
    set: value => {
      store.commit('user/CHANGE_APP_MODE', value)

      // Save app mode for future
      localStorage.setItem('app-mode', value)

      // Theme switch (Seller / Purchaser)
      themes.value = value === 'seller' ? themeConfig.themeVariants.seller : themeConfig.themeVariants.purchaser
    },
  })

  // Theme use (Seller / Purchaser)
  // const appTheme = computed({
  //   get: () => (isDark.value ? themes.value.dark : themes.value.light),
  //   set: value => {
  //     themes.value = value === 'seller' ? themeConfig.themeVariants.seller : themeConfig.themeVariants.purchaser
  //   },
  // })
  const appTheme = computed(() => (isDark.value ? themes.value.dark : themes.value.light))

  // TODO - Eliminar (Usamos appTheme)
  const appModeColor = computed(() => {
    if (appMode.value === 'seller') return sellerColor
    if (appMode.value === 'purchaser') return purchaserColor

    return null
  })

  // TODO - Eliminar (Usamos appTheme)
  const appModeVariant = computed(() => {
    if (appMode.value === 'seller') return sellerVariant
    if (appMode.value === 'purchaser') return purchaserVariant

    return sellerVariant
  })

  // TODO - Eliminar (Usamos appTheme)
  const appModeVariantLight = computed(() => {
    if (appMode.value === 'seller') return sellerVariantLight
    if (appMode.value === 'purchaser') return purchaserVariantLight

    return sellerVariantLight
  })

  //   const appModeIcon = computed(() => {
  //     if (appMode.value === 'seller') return 'ToggleLeftIcon'
  //     if (appMode.value === 'purchaser') return 'ToggleRightIcon'

  //     return null
  //   })

  const isSeller = computed(() => appMode.value === 'seller')
  const isPurchaser = computed(() => appMode.value === 'purchaser')

  // User Data
  //    Get: Read store data
  //    Set: Not in use (Por ahora usamos store directamente)
  const userData = computed({
    get: () => store.state.user.userData,
    set: val => {
      // store.commit('user/CHANGE_APP_MODE', val)
      store.dispatch('user/SET', val)
    },
  })

  // Usamos Watch para activar reactividad ante cambios de estado
  watch(() => store.state.user.userData, () => {
    userData.value = store.state.user.userData
  })

  // >>> SFR 25.01.2022 - Table Visibility
  // ------------------------------------------------
  // isTableVisible: Boolean for [ProductDemander, MarketplaceCatalog, ProductSupplier]
  // ------------------------------------------------
  // const isTableVisible = computed({
  //   get: () => store.state.user.isTableVisible,
  //   set: value => {
  //     store.commit('user/UPDATE_TABLE_VISIBILITY', value)
  //     localStorage.setItem('is-table-visible', value) // Save for login again
  //   },
  // })
  const isTableVisibleProductDemander = computed({
    get: () => store.state.user.isTableVisibleProductDemander,
    set: value => {
      store.commit('user/UPDATE_TABLE_VISIBILITY_PRODUCT_DEMANDER', value)
      localStorage.setItem('is-table-visible-product-demander', value) // Save for login again
    },
  })
  const isTableVisibleProductSupplier = computed({
    get: () => store.state.user.isTableVisibleProductSupplier,
    set: value => {
      store.commit('user/UPDATE_TABLE_VISIBILITY_PRODUCT_SUPPLIER', value)
      localStorage.setItem('is-table-visible-product-supplier', value)
    },
  })
  const isTableVisibleMarketplaceCatalog = computed({
    get: () => store.state.user.isTableVisibleMarketplaceCatalog,
    set: value => {
      store.commit('user/UPDATE_TABLE_VISIBILITY_MARKETPLACE_CATALOG', value)
      localStorage.setItem('is-table-visible-marketplace-catalog', value)
    },
  })
  const isTableVisibleContact = computed({
    get: () => store.state.user.isTableVisibleContact,
    set: value => {
      store.commit('user/UPDATE_TABLE_VISIBILITY_CONTACT', value)
      localStorage.setItem('is-table-visible-contact', value)
    },
  })

  return {
    // AppMode (Seller/Purchaser)
    appMode,
    isSeller,
    isPurchaser,

    // UI colors
    appTheme,
    appModeColor,
    appModeVariant,
    appModeVariantLight,

    // appModeIcon,

    // UserData
    userData,

    // TableVisibility
    isTableVisibleProductDemander,
    isTableVisibleProductSupplier,
    isTableVisibleMarketplaceCatalog,
    isTableVisibleContact,
  }
}
