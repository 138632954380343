export default {
  // Endpoints
  loginEndpoint: '/auth/access-login',
  refreshEndpoint: '/auth/access-token',
  registerEndpoint: '/auth/register',
  registerWizardEndpoint: '/auth/register-wizard',
  updateEndpoint: '/auth/update',
  logoutEndpoint: '/auth/logout',

  // Reset password
  resetPasswordRequest: '/auth/reset-password-request', // Solicitar reset
  resetPasswordValidation: '/auth/reset-password-validation', // Validar usuario vía token procedencia de correo
  resetPassword: '/auth/reset-password', // reset password

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
}
