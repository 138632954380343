<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

// Import from vue-toastification/composition, not vue-toastification
// Also import the toast's css
import { provideToast } from 'vue-toastification/composition'
import 'vue-toastification/dist/index.css'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue' // use this
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutNoAuth from '@/layouts/variants/blank/LayoutNoAuth.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutNoAuth,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      // >>> SFR Usamos LayoutNoAuth
      // if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'blank') return 'layout-no-auth'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav` // LayoutContentVerticalNav

      return null
    })

    useDynamicVh()

    // Pass the Plugin Options here
    provideToast({ timeout: 3000 })

    // This is similar to `Vue.use(Toast)`, but will not register `$toast` to the Vue instance.

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>

<!-- Cargado en main.js -->
<!-- Important load global CSS for perfect-scrollbar -->
<!-- <style lang="scss">
  // @import "../../../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";
  @import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style> -->
