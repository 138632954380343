export default [

  // User Profile Management
  {
    path: '/profile',
    name: 'apps-user-profile',

    // component: () => import('@/views/apps/user/user-view/UserView.vue'),
    // component: () => import('@/views/apps/user/user-profile/UserProfile.vue'),
    component: () => import('@/views/apps/user/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },

]
