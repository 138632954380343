export default [

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/auth/register-wizard/RegisterWizard.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/auth/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  // {
  //   path: '/register',
  //   name: 'auth-register',

  //   // component: () => import('@/views/pages/authentication/Register.vue'),
  //   component: () => import('@/views/pages/account-wizard/AccountWizard.vue'),

  //   // component: () => import('@/views/pages/account-wizard/AccountWizardFormStep5Profile.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/register-validation',
  //   name: 'auth-register-validation',
  //   component: () => import('@/views/pages/authentication/RegisterValidation.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/pages/auth/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },

  // {
  //   path: '/reset-password',
  //   name: 'auth-reset-password',
  //   component: () => import('@/views/pages/authentication/ResetPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },

]
