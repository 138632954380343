<template>
  <!-- class="d-flex flex-column align-center" -->
  <div
    class="navbarH-flex"
    :style="{ 'border-bottom': `${isHeaderActive ? `4px solid ${activeColor}` : 'unset' }` }"
  >
    <!-- <v-icon :color="$vuetify.theme.dark"></v-icon> -->
    <!-- {{ $vuetify.theme.dark }} -->
    <div
      v-if="!itemLocal.children"
      style="width: 100%;"
    >
      <!-- <router-link
        :to="itemLocal.route"
      > -->
      <a
        style="text-decoration: none; color: unset; width: 100%;"
        class="navbarH"
        @click="redirectWithReload(itemLocal.route)"
      >
        <!-- v-if="itemLocal.badge > 0" -->
        <v-badge
          :value="itemLocal.badge > 0"
          :color="activeColor"
          :content="itemLocal.badge"
          left
          offset-x="44"
          offset-y="14"
        >
          <v-icon
            size="28"
            :color="activeColor"
          >
            {{ itemLocal.icon }}
          </v-icon>
        </v-badge>
        <div class="d-sm-flex d-none">
          <span class="navbarH-item-text">{{ itemLocal.title }}</span>
        </div>
      </a>
      <!-- </router-link> -->
    </div>
    <!-- Menu contextual -->
    <v-menu
      v-else
      offset-y
      min-width="230"
      content-class="user-profile-menu-content"
      center
      nudge-bottom="4"
    >
      <template
        v-slot:activator="{ attrs, on }"
      >
        <div
          class="navbarH"
          style="width: 100%;"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :value="itemLocal.badge > 0"
            :color="activeColor"
            :content="itemLocal.badge"
            left
            offset-x="44"
            offset-y="14"
          >
            <v-icon
              size="28"
            >
              {{ itemLocal.icon }}
            </v-icon>
          </v-badge>
          <div class="d-sm-flex d-none">
            <span class="navbarH-item-text">{{ itemLocal.title }}</span>
          </div>
        </div>
      </template>
      <v-list
        dense
        flat
      >
        <!-- Header -->
        <div class="d-flex align-content-between align-items-center pb-3 pt-2">
          <span class="pl-2 mr-auto text--primary font-weight-semibold mb-n1">
            {{ itemLocal.title }}
          </span>
          <v-badge
            v-if="itemLocal.badge > 0"
            class="mr-2"
            inline
            rounded="sm"
            :color="activeColor"
            :content="itemLocal.badge"
          >
          </v-badge>
        </div>

        <v-divider></v-divider>

        <!-- Childrems -->
        <!-- :to="child.route" -->
        <!-- class="v-list-item--exact-active" -->
        <v-list-item
          v-for="(child, index) in itemLocal.children"
          :key="index"
          :class="child.isActive ? 'v-list-item--exact-active' : ''"
          @click="redirectWithReload(child.route)"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ child.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              {{ child.title }}
              <!-- <v-spacer></v-spacer>
              <div @click="/**/">
                <v-icon size="28">
                  {{ icons.mdiRefresh }}
                </v-icon>
              </div> -->
              <v-spacer></v-spacer>
              <v-badge
                v-if="child.badge > 0"
                class="mt-0"
                inline
                rounded="sm"
                :color="activeColor"
                :content="child.badge"
              >
              </v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mdiRefresh } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import {
  isDynamicRouteActiveLink, redirectWithReload, isDynamicRouteNameActive,
} from '@core/utils'

// import { initialAbility } from '@/plugins/acl/config'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    activeColor: {
      type: String,
      default: '#1c6bd1',
    },
  },

  // Escuchamos el router: Cuando cambia comprobamos
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateIsHeaderActive()
      },
    },
  },

  setup(props) {
    const itemLocal = ref(JSON.parse(JSON.stringify(props.item)))

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    watch(() => props.item, () => {
      itemLocal.value = JSON.parse(JSON.stringify(props.item))
    })

    // Control de tab activo
    const isHeaderActive = ref(false)

    const updateIsHeaderActive = () => {
      // Comprobamos si la ruta está activa
      // Parent with Childrens: Comprobamos el active link + Cada item
      // Parent NoChild: Comprobamos la ruta del padre directamente
      if (props.item.children !== undefined) {
        // isHeaderActive.value = props.item.children.some(child => isDynamicRouteNameActive(child.route))
        isHeaderActive.value = isDynamicRouteActiveLink(props.item.route)

        // itemLocal.value = itemLocal.children.map(item => item.isActive = isDynamicRouteNameActive(child.route))
        // Comprobar cada hijo si es un Active link
        const newChildrenList = []
        itemLocal.value.children.forEach(child => {
          const newChild = child
          newChild.isActive = isDynamicRouteNameActive(newChild.route)
          newChildrenList.push(newChild)
        })
        itemLocal.value.children = newChildrenList
      } else {
        isHeaderActive.value = isDynamicRouteNameActive(props.item.route)
      }

      // console.log(isHeaderActive.value, props.item, router.currentRoute)
    }

    return {
      itemLocal,

      // Route header
      isHeaderActive,
      updateIsHeaderActive,

      // Router utils
      redirectWithReload,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .navbarH-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .navbarH {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 52px;
    min-width: 80px;
  }
  .navbarH-item-text {
    font-size: 16.2px;
  }

  @media screen and (max-width: 853px) {
    // .nav-item {
        // margin: 0 calc(var(--spacing-three-x)/2);
        // margin: 0 calc(43.2px/2);
    // }
    .navbarH {
        // min-width: 48px;
    }
    .navbarH-item-text {
        // display: none;
    }

  }
  @media screen and (max-width: 553px) {
    .navbarH {
        min-width: 38px;
    }
  }
  @media screen and (max-width: 420px) {
    .navbarH {
        min-width: 24px;
    }
    .navbarH-item-text {
        display: none;
    }
  }

  // Ver LeftSidebarContent
  .v-list--dense .v-list-item {
    height: 38px;
    min-height: 38px;
    border-left: 8px solid transparent;

    &.v-list-item--exact-active {
      border-color: var(--v-primary-base);
    }

    .v-list-item__icon {
      align-items: end;
    }

    // label dot
    .label-dot {
      .v-badge__badge {
        width: 12px !important;
        height: 12px !important;
        border-radius: 10px;
      }
    }
  }

</style>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 45px !important;
    }
  }
</style>
