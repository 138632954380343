// Marketplace: /marketplace/contact/request      => /marketplace/contact-request
// Network:     /network/contact/request/inbound  => /network/contact-request-inbound
export default [

  // Contact Request (default params: published)
  // {
  //   path: '/marketplace/contact-request/published',
  //   name: 'apps-contact-request',
  //   component: () => import('@/views/apps/contact-request/contact-request/ContactRequest.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/marketplace/contact-request/:folder',
    name: 'apps-contact-request-folder',
    component: () => import('@/views/apps/contact-request/contact-request/ContactRequest.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketplace',
    },
    beforeEnter(to, _, next) {
      if (['published', 'draft', 'finished', 'starred', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/marketplace/contact-request/:label',
    name: 'apps-contact-request-label',
    component: () => import('@/views/apps/contact-request/contact-request/ContactRequest.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketplace',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // Contact Request Inbound (Default params: received)
  // {
  //   path: '/network/contact-request-inbound/received',
  //   name: 'apps-contact-request-inbound',
  //   component: () => import('@/views/apps/contact-request/contact-request-inbound/ContactRequestInbound.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/network/contact-request-inbound/:folder',
    name: 'apps-contact-request-inbound-folder',
    component: () => import('@/views/apps/contact-request/contact-request-inbound/ContactRequestInbound.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-contact-request-inbound',
    },
    beforeEnter(to, _, next) {
      if (['received', 'accepted', 'rejected', 'starred', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/network/contact-request-inbound/label/:label',
    name: 'apps-contact-request-inbound-label',
    component: () => import('@/views/apps/contact-request/contact-request-inbound/ContactRequestInbound.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-contact-request-inbound',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

]
