import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

// Modules
import authStoreModule from '@/services/api/modules/auth/authStoreModule'
import userStoreModule from '@/services/api/modules/user/userStoreModule'
import companyStoreModule from '@/services/api/modules/company/companyStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,

    auth: authStoreModule,
    user: userStoreModule,
    company: companyStoreModule,
  },
})
