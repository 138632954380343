import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

// const sellerColor = '#1c6bd1' // Blue
// const purchaserColor = '#28c76f' // Green
const themeVariants = {
  seller: {
    light: {
      primary: '#1c6bd1', // Fishnet blue
      accent: '#C58712', // Fishnet gold
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#9155FD',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
  purchaser: {
    light: {
      primary: '#28c76f', // Fishnet green
      secondary: '#6A8DEE',
      accent: '#18BC5A',
      success: '#18BC5A',
      info: '#6BA6F9',
      warning: '#F7EB13',
      error: '#FF4538',
    },
    dark: {
      primary: '#FF4538',
      secondary: '#6A8DEE',
      accent: '#18BC5A',
      success: '#18BC5A',
      info: '#6BA6F9',
      warning: '#F7EB13',
      error: '#FF4538',
    },
  },
}

const themeConfig = {
  app: {
    name: 'FishNet',
    logo: require('@/assets/logo.png'), // require('@/assets/images/svg/logo.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'bordered', // default, bordered, semi-dark
    contentWidth: 'boxed',
  },
  menu: {
    isMenuHidden: true,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },

  // Theme varians (Seller and purchaser)
  themes: themeVariants.seller,
  themeVariants,
}

export default themeConfig
