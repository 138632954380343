export default [

  // Chat
  {
    path: '/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/chat/Chat.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'chat',
    },
  },
  {
    path: '/contact',
    name: 'apps-contact-list',
    component: () => import('@/views/apps/chat/contact-list/ContactList.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'chat',
    },
  },

]
