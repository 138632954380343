/* eslint-disable no-console */

// SFR Notes
// Para probar el service-worker en desarrollo usamos service-worker-dev.js que es una copia manual
//  Se deposita en /dist porque está em public
// const base = process.env.BASE_URL
const base = process.env.VUE_APP_BASE
const serviceWorkedFile = (base.search('localhost') !== -1)
  ? `${process.env.BASE_URL}service-worker-dev.js`
  : `${process.env.BASE_URL}service-worker.js`

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(serviceWorkedFile, { scope: '/' })
      if (registration.installing) {
        console.log('Service worker installing')
      } else if (registration.waiting) {
        console.log('Service worker installed')
      } else if (registration.active) {
        console.log('Service worker active')

        // registration.active.postMessage({
        //   action: 'redirect-from-notificationclick',
        //   url: 'https://goo.gl/AFskqB',
        // })
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  }
}
registerServiceWorker()

// import { register } from 'register-service-worker'
//
// if (serviceWorkedFile) {
//   register(serviceWorkedFile, {
//     ready() {
//       console.log(
//         'App is being served from cache by a service worker.\n'
//         + 'For more details, visit https://goo.gl/AFskqB',
//       )
//     },
//     registered() {
//       console.log('Service worker has been registered.')
//     },
//     cached() {
//       console.log('Content has been cached for offline use.')
//     },
//     updatefound() {
//       console.log('New content is downloading.')
//     },
//     updated() {
//       console.log('New content is available; please refresh.')
//     },
//     offline() {
//       console.log('No internet connection found. App is running in offline mode.')
//     },
//     error(error) {
//       console.error('Error during service worker registration:', error)
//     },
//   })
// }
