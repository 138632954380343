<template>
  <div>
    <!-- TODO: El navbar ya se indica en el padre -->
    <!-- <v-app-bar
      ref="refAppBar"
      :app="true"
      fixed
      :absolute="true"
      flat
      :elevate-on-scroll="false"
      height="64px"
    > -->
    <!-- class="d-flex align-center justify-space-between" -->
    <!-- class="navbar-content-container" -->
    <div class="grid-container">
      <AppNavBarItem
        v-for="item in navbarHItems"
        :key="item.id"
        :item="item"
        :active-color="appTheme.primary"
      />
      <AppNavBarUserItem
        style="height: 56px !important;"
        :toggle-vertical-menu-sidebar-active="toggleVerticalMenuSidebarActive"
      />
    </div>
    <!-- </v-app-bar> -->

    <!-- User Navigation Drawer -->
    <AppNavBarUserNavigationDrawer
      v-model="isVerticalMenuSidebarActive"
    />
  </div>
</template>

<script>
import {
  mdiHome,
  mdiMessageText, mdiContactsOutline,
  mdiViewGrid, mdiEmailSend, mdiShopping, mdiEmail,
} from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'

// UI Services Custom
// import { getVuetify } from '@core/utils'
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Menu items (App)
import AppNavBarItem from '@/components/AppNavBar/AppNavBarItem.vue'

// User menu and NavigationDrawer
import AppNavBarUserItem from '@/components/AppNavBar/AppNavBarUser.vue'
import AppNavBarUserNavigationDrawer from '@/components/MenuSideBarUser/MenuNavigationDrawer.vue'

export default {
  components: {
    AppNavBarItem,
    AppNavBarUserItem,
    AppNavBarUserNavigationDrawer,
  },

  // props: {
  //   items: {
  //     type: Array,
  //     required: true,
  //     default: () => [],
  //   },
  //   activeColor: {
  //     type: String,
  //     default: '#1c6bd1',
  //   },
  // },

  setup() {
    // User Side menu
    const isVerticalMenuSidebarActive = ref(false)
    const toggleVerticalMenuSidebarActive = () => {
      isVerticalMenuSidebarActive.value = !isVerticalMenuSidebarActive.value
    }

    // appMode [seller/purchaser]
    const {
      isSeller, isPurchaser, appModeColor, appTheme,
    } = useAppConfigUser()

    // Theme color
    // const vuetify = getVuetify()
    // console.log(vuetify.theme.themes)
    // console.log(vuetify.theme.themes[vuetify.theme.dark ? 'dark' : 'light'].primary)

    // const appTheme = ref(null)
    // appTheme.value = vuetify.theme.themes[vuetify.theme.dark ? 'dark' : 'light']
    // const appTheme = computed(() => vuetify.theme.themes[vuetify.theme.dark ? 'dark' : 'light'])
    // var(--v-primary-base)

    //
    // const productSupplierFavoritesPendingCount = computed(() => store.getters['user/productSupplierFavoritesPendingCount'])
    const contactRequestsPendingCount = computed(() => store.getters['user/contactRequestsPendingCount'])
    const contactRequestInboundsPendingCount = computed(() => store.getters['user/contactRequestInboundsPendingCount'])
    const campaignsPendingCount = computed(() => store.getters['user/campaignsPendingCount'])
    const campaignInboundsPendingCount = computed(() => store.getters['user/campaignInboundsPendingCount'])
    const chatRoomUsersPendingCount = computed(() => store.getters['user/chatRoomUsersPendingCount'])

    // const productSupplierFavoritesPendingCount = ref(0)
    // const contactRequestsPendingCount = ref(0)
    // const contactRequestInboundsPendingCount = ref(0)
    // const campaignsPendingCount = ref(2)
    // const campaignInboundsPendingCount = ref(1)
    // const chatRoomUsersPendingCount = ref(0)

    // *** Usamos Computed para que actualize el badge *** //
    //  navActiveLink: enlaza con el mismo navActiveLink del Router (Ver Router)

    const itemHome = {
      id: 'home',
      title: 'Home',
      icon: mdiHome,
      route: { name: 'home', meta: { navActiveLink: 'home' } },
    }

    const itemChat = computed(() => ({
      id: 'chat',
      title: 'Chat',
      icon: mdiMessageText,
      route: { name: 'apps-chat', meta: { navActiveLink: 'chat' } },
      badge: chatRoomUsersPendingCount.value,
    }))
    const itemContact = computed(() => ({
      id: 'contact',
      title: 'Contact',
      icon: mdiContactsOutline,
      route: { name: 'apps-contact-list', meta: { navActiveLink: 'chat' } },
      badge: chatRoomUsersPendingCount.value,
    }))

    // Seller paths
    const itemSeller = computed(() => ({
      id: 'marketing',
      title: 'Marketing',
      icon: mdiViewGrid,
      route: { name: 'apps-product-supplier-list', meta: { navActiveLink: 'marketing' } }, // navActiveLink
      badge: campaignsPendingCount.value,
      children: [
        {
          title: 'Products',
          icon: mdiViewGrid,
          route: { name: 'apps-product-supplier-list' },
        },
        {
          title: 'Campaign',
          icon: mdiEmailSend,
          route: { name: 'apps-campaign-folder', params: { folder: 'published' } },
          route2: { name: 'apps-campaign' },
          badge: campaignsPendingCount.value,
        },
      ],
    }))

    // Purchaser paths
    //  navActiveLink: marketplace => Enlaza con todos sus hijos (Ver Router)
    const itemPurchaser = computed(() => ({
      id: 'marketplace',
      title: 'Marketplace',
      icon: mdiShopping,
      route: { name: 'apps-marketplace-catalog', meta: { navActiveLink: 'marketplace' } }, // navActiveLink
      footer: { title: 'Go Marketplace', route: { name: 'apps-marketplace-products' } },
      badge: contactRequestsPendingCount.value,
      children: [
        {
          title: 'Interest List',
          icon: mdiViewGrid,
          route: { name: 'apps-product-demander-list' },
        },
        {
          title: 'Marketplace',
          icon: mdiShopping,
          route: { name: 'apps-marketplace-catalog' },
        },

        // {
        //   title: 'Checkout',
        //   icon: mdiHeartOutline,
        //   route: { name: 'apps-marketplace-checkout' },
        //   badge: productSupplierFavoritesPendingCount.value,
        // },

        // { name: 'apps-contact-request' } => Si indicamos esto va a la ruta que tenga por defecto
        {
          title: 'ContactRequest',
          icon: mdiEmailSend,
          route: { name: 'apps-contact-request-folder', params: { folder: 'published' } },
          route2: { name: 'apps-contact-request' },
          badge: contactRequestsPendingCount.value,
        },
      ],
    }))

    // { name: 'apps-contact-request-inbound' } => Si indicamos esto va a la ruta que tenga por defecto
    const networkSeller = computed(() => ({
      id: 'network-seller',
      title: 'Network-S',
      icon: mdiEmail,
      route: { name: 'apps-contact-request-inbound-folder', params: { folder: 'received' }, meta: { navActiveLink: 'network' } },
      route2: { name: 'apps-contact-request-inbound' },
      badge: contactRequestInboundsPendingCount.value,
    }))

    const networkPurchaser = computed(() => ({
      id: 'network-purchaser',
      title: 'Network-P',
      icon: mdiEmail,
      route: { name: 'apps-campaign-inbound-folder', params: { folder: 'received' }, meta: { navActiveLink: 'network' } },
      route2: { name: 'apps-campaign-inbound', meta: { navActiveLink: 'network' } },
      badge: campaignInboundsPendingCount.value,
    }))

    // Usamos el estado
    // Cuando cambie el estado se ejecuta computed: Esto se puede cambiar por watch
    //  return [...navbarHStatic.value, itemSeller.value]
    //  return [...navbarHStatic.value, itemPurchaser.value]
    const navbarHItems = computed(() => {
      if (isSeller.value) {
        return [itemHome, itemSeller.value, networkSeller.value, itemChat.value]
      }
      if (isPurchaser.value) {
        return [itemHome, itemPurchaser.value, networkPurchaser.value, itemChat.value]
      }

      return [itemHome]
    })

    return {
      // User Side menu control
      isVerticalMenuSidebarActive,
      toggleVerticalMenuSidebarActive,

      // Menu items
      navbarHItems,
      appModeColor, // AppMode Colors
      appTheme,
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/layouts/styles/_variables';

  .app-content-container {
      max-width: $content-max-width;
  }
</style>

<style lang="scss" scoped>

  // Usamos Grid para repartir equitativamente el menu
  .grid-container {
    // width: calc(100%);
    width: 100vw; // Mejora el fliker de recalculo del ancho
    max-width: calc(1440px - (1.5rem * 0)); // Ver .v-application .v-app-bar
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    // gap: 1em;

    // max-width: 66.6667rem;
    // outline: .0556rem solid red;
    // border-bottom: black solid 1px;
  }
  .grid-container > * {
    // border: .0556rem solid black;
    // border-radius: 0rem;
  }

  // Flex seria la opcion 2: https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/
  .flex-container {
    // Flex del 100%
    display: flex;
    flex-basis: 100%;

    // outline: .0556rem solid red;
    // border-bottom: black solid 1px;
  }
  .flex-container > * {
    // Flex descomposición
    flex: 1 0 auto;
    // flex-grow: 1; // Ajustar al ancho
    // flex-shrink: 0; // No encojer
    // flex-basis: auto;
    width: 10px; // Este es un truco: Al indicar un ancho igual, flex calcula equitativamente el ancho

    // border: .0556rem solid black;
    // border-radius: 0rem;
  }

  // Quitamos el padding del v-toolbar
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding: 0
    }
  }
</style>
