export default [

  // Marketplace >>> Marketplace Catalog [P]
  {
    path: '/marketplace/catalog',
    name: 'apps-marketplace-catalog',
    component: () => import('@/views/apps/marketplace/catalog/MarketplaceCatalog.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketplace',
    },
  },

  // Marketplace >>> Marketplace Checkout [P]
  {
    path: '/marketplace/checkout',
    name: 'apps-marketplace-checkout',
    component: () => import('@/views/apps/marketplace/checkout/MarketplaceCheckout.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketplace',
    },
  },

  // Marketplace >>> Marketplace Suppliers [P]
  // {
  //   path: '/marketplace/suppliers',
  //   name: 'apps-suppliers',
  //   component: () => import('@/views/apps/contact-request/contact-request/ContactRequest.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },

]
