export default {
  global: {
    appname: 'FishNet',
    yes: 'Yes',
    no: 'No',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
  },

  // Login Form
  Login: {
    wellcome_title: 'Welcome to FishNet! 👋🏻',
    wellcome_description: 'Please sign-in to your account and start the adventure',
    new_on_platform: 'New on our platform?',
    create_an_account: 'Create an account',
    forgot_password: 'Forgot Password?',
    form: {
      username: 'Email',
      username_placeholder: 'Email',
      password: 'Password',
      password_placeholder: 'Password',
      rememberme: 'Remember Me',
      submit: 'LOGIN',
    },
  },
  ForgotPassword: {
    forgot_password: 'Forgot Password? 🔒',
    forgot_password_description: 'Enter your email and we will send you instructions to reset your password',
    back_to_login: 'Back to login',
    form: {
      username: 'Email',
      username_placeholder: 'Emails',
      submit: 'Send reset link',
    },
  },
  ResetPassword: {
    reset_password: 'Reset Password? 🔒',
    reset_password_description: 'Your new password must be different from previously used passwords',
    back_to_login: 'Back to login',
    form: {
      password: 'New Password',
      password_placeholder: '············',
      confirm_password: 'Confirm Password',
      confirm_password_placeholder: '············',
      submit: 'Set New Password',
    },
  },
  RegisterWizard: {
    header: {
      title: 'Register information',
      subtitle: 'Only for administrators',
      text: 'You are about to register your company on the FishNet Expo platform by creating the administrator account. We recommend that this operation be done with the authorization of the Management or the Commercial Manager of the company.',
      text_p2: "Each company may have infinite individual accounts, but all of them will be opened and managed from the administrator's account.",
      text_p3: 'If your company is registered in FishNet Expo, talk to the administrator to register you.',
    },
    body: {
      tab1: {
        menu: 'Account',
        title: 'Account',
        subtitle: 'Enter your account details as administrator',
        form: {
          username: 'Email',
          username_placeholder: 'john@example.com',
          password: 'Password',
          password_placeholder: 'Password',
          first_name: 'First Name',
          first_name_placeholder: 'First Name',
          last_name: 'Last Name',
          last_name_placeholder: 'Last Name',
          language: 'FishNet Language',
          agree: 'Terms',
          agree_term1: 'I agree',
          agree_term2: 'to privacy policy & terms',
        },
      },
      tab2: {
        menu: 'Company',
        title: 'Company',
        subtitle: 'Enter your company information',
        form: {
          name: 'Name',
          name_placeholder: 'Company name',
          phone: 'Mobile number with country code',
          phone_placeholder: 'Mobile number with country code',
          country: 'Country',
          country_placeholder: 'Select the country',
          region: 'Region',
          region_placeholder: 'Seleccione una region',
          city: 'City',
          city_placeholder: 'City',
          zip: 'ZIP code',
          zip_placeholder: 'ZIP',
          street: 'Street',
          street_placeholder: 'Street',
        },
      },
      tab3: {
        menu: 'Plan',
        title: 'Plan',
        subtitle: 'Select the most convenient plan for your company',
        pricing_link: 'See pricing',
      },
    },
    footer: {
      action_back_to_login: 'Back to login',
      back: 'Back',
      next: 'Next',
      finish: 'Register to Fishnet',
    },
  },

  AccountSettings: {
    body: {
      tab1: {
        title: 'Account',
        image: {
          buttom_upload: 'Upload new photo',
          buttom_reset: 'Reset',
          text: 'Allowed JPG, GIF or PNG. Max size of 800K',
        },
        form: {
          first_name: 'First Name',
          first_name_placeholder: 'First Name',
          last_name: 'Last Name',
          last_name_placeholder: 'Last Name',
          language: 'FishNet Language',
          submit: 'Save',
          reset: 'Reset',
        },
      },
      tab2: {
        title: 'Security',
        form: {
          password_current: 'Current Password',
          password_new: 'New Password',
          password_confirm: 'Confirm New Password',
          submit: 'Change Password',
          reset: 'Reset',
        },
      },
      tab3: {
        title: 'Social',
        form: {
          whatsapp: 'Whatsapp',
          instagram: 'Instagram',
          facebook: 'Facebook',
          linkedin: 'Linkedin',
          telegram: 'Telegram',
          submit: 'Save',
          reset: 'Reset',
        },
      },
      tab4: {
        title: 'Notifications',
        push: {
          title: 'Push Notifications',
        },
        activity: {
          title: 'Activity',
          followMe: 'Email me when someone follows me',
        },
        application: {
          title: 'Application',
          newsAnnouncements: 'News and announcements',
        },
      },
    },
  },

  CompanySettings: {
    body: {
      tab1: {
        title: 'Company',
        avatar: {
          buttom_upload: 'Upload Avatar',
          buttom_reset: 'Reset',
          text: 'Allowed JPG, GIF or PNG. Max size of 800K',
        },
        form: {
          name: 'Company name',
          slug: 'Unique company name',
          taxid: 'TAX Identification',
          country: 'Company Country',
          country_placeholder: 'Select the country',
          submit: 'Save',
          reset: 'Reset',
        },
      },
      tab2: {
        title: 'Security',
        form: {
          country: 'Country',
          country_placeholder: 'Select the country',
          region: 'Region',
          region_placeholder: 'Seleccione una region',
          city: 'City',
          city_placeholder: 'City',
          zip: 'ZIP code',
          zip_placeholder: 'ZIP',
          street: 'Street',
          street_placeholder: 'Street',
          submit: 'Save',
          reset: 'Reset',
        },
      },
      tab3: {
        title: 'Users',
        filters: {
          title: 'Filters',
          company_role: 'Select Company Role',
          status: 'Select Status',
        },
        table: {
          search: 'Search',
          buttom_add: 'Add User',
          action_delete: 'Delete',
          dialog_delete: {
            title: 'Delete User',
            text: 'The user will be removed from the system permanently.',
            button_text: 'Delete',
          },
        },
        form: {
          header_add: 'Add User',
          header_edit: 'Edit User',
          first_name: 'Name',
          username: 'Email',
          options: {
            text: 'Company profile',
            seller: 'Seller',
            purchaser: 'Purchaser',
          },
          company_role: 'Company Role',
          telegram: 'Telegram',
          submit_add: 'Add',
          submit: 'Save',
          reset: 'Reset',
        },
      },
      tab4: {
        title: 'WebView',
        header: {
          image: {
            buttom_upload: 'Upload',
            buttom_reset: 'Reset',
            text: 'Allowed JPG, GIF or PNG. Max size of 800K',
          },
        },
        about: {
          title: 'About',
        },
        users: {
          title: 'Personal',
        },
      },
    },
  },

  ProductFilters: {
    title: 'Filters',
    status: 'Select Status',
    product_category: 'Category',
    product: 'Specie',
    product_conservation: 'Conservation',
    product_presentation: 'Presentation',
  },

  ProductSupplierList: {
    title: 'Catalog',
    table: {
      search: 'Search',
      status_filter: 'Select Status',
      buttom_add: 'Add Product',
      action_delete: 'Delete',
      dialog_delete: {
        title: 'Delete Product',
        text: 'The Product will be removed from your catalog.',
        button_text: 'Delete',
      },
      dialog_delete_dependencies: {
        title: 'Delete Product Dependencies',
        text: 'The Product will be removed from your catalog. (Including dependencies)',
        button_text: 'Delete (Force)',
      },
    },
    form: {
      header_add: 'Add Product',
      header_edit: 'Edit Product',
      name: 'Comercial name',
      product: 'Specie',
      product_conservation: 'Conservation',
      product_presentation: 'Presentation',
      tags: 'Tags',

      submit_add: 'Add',
      submit: 'Save',
      reset: 'Reset',
    },
  },
  ProductSupplierReasignDialog: {
    title: 'Product reasign',
    text: 'The Product will reasign to...',
    button_text: 'Reasign',
  },

  ProductDemanderList: {
    title: 'Interest List',
    table: {
      search: 'Search',
      status_filter: 'Select Status',
      buttom_add: 'Add Product',
      action_delete: 'Delete',
      dialog_delete: {
        title: 'Delete Product',
        text: 'The Product will be removed from your catalog.',
        button_text: 'Delete',
      },
    },
    form: {
      header_add: 'Add Product',
      header_edit: 'Edit Product',
      name: 'Name',
      product_category: 'Category',
      product: 'Specie',
      product_conservation: 'Conservation',
      product_presentation: 'Presentation',

      submit_add: 'Add',
      submit: 'Save',
      reset: 'Reset',
    },
  },

  // Marketplace
  MarketplaceProductSupplierDialog: {
    title: 'Contact Request',
    text: 'Will send a contact Request...',
    button_text: 'Connect',
  },

  CampaignComposerWizard: {
    header: {
      title: 'Campaign composer wizard',
      subtitle: 'Only for administrators',
      text: 'You are about to register your company on the FishNet Expo platform by creating the administrator account. We recommend that this operation be done with the authorization of the Management or the Commercial Manager of the company.',
      text_p2: "Each company may have infinite individual accounts, but all of them will be opened and managed from the administrator's account.",
      text_p3: 'If your company is registered in FishNet Expo, talk to the administrator to register you.',
    },
    body: {
      tab1: {
        menu: 'Campaign',
        title: 'Campaign',
        subtitle: 'Enter your account details as administrator',
        form: {
          name: 'Name',
          name_placeholder: 'Campaign name',
          product_supplier: 'Product',
          product_supplier_placeholder: 'Select a Product',
          quantity: 'Quantity',
          um: 'UM',
          um_placeholder: 'Select a UM',
          price: 'Price',
          currency: 'Currency',
          currency_placeholder: 'Select a Currency',
          subject: 'Subject',
          message: 'Message',
        },
      },
      tab2: {
        menu: 'Recipients',
        title: 'Recipients',
        subtitle: 'Enter the recipients to send the campaign',
        form: {
          recipients_internal: 'Contacts',
          recipients_internal_placeholder: 'Select your contacts',
          recipients_blind_scan: 'Country',
          recipients_blind_scan_placeholder: 'Select a country',
        },
      },
    },
    footer: {
      back: 'Back',
      next: 'Next',
      finish: 'Save',
    },
  },

  // Countries
  CountrySelect: {
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    'American Samoa': 'American Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctica',
    'Antigua and Barbuda': 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    'Bonaire, Sint Eustatius and Saba': 'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    Brazil: 'Brazil',
    'British Indian Ocean Territory': 'British Indian Ocean Territory',
    'Brunei Darussalam': 'Brunei Darussalam',
    Bulgaria: 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    'Cape Verde': 'Cape Verde',
    'Cayman Islands': 'Cayman Islands',
    'Central African Republic': 'Central African Republic',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    'Christmas Island': 'Christmas Island',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    'Congo, Republic of the (Brazzaville)': 'Congo, Republic of the (Brazzaville)',
    'Congo, the Democratic Republic of the (Kinshasa)': 'Congo, the Democratic Republic of the (Kinshasa)',
    'Cook Islands': 'Cook Islands',
    'Costa Rica': 'Costa Rica',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Cyprus: 'Cyprus',
    'Czech Republic': 'Czech Republic',
    'Côte d Ivoire, Republic of': "Côte d'Ivoire, Republic of",
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    'Falkland Islands (Islas Malvinas)': 'Falkland Islands (Islas Malvinas)',
    'Faroe Islands': 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    'French Guiana': 'French Guiana',
    'French Polynesia': 'French Polynesia',
    'French Southern and Antarctic Lands': 'French Southern and Antarctic Lands',
    Gabon: 'Gabon',
    'Gambia, The': 'Gambia, The',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    'Heard Island and McDonald Islands': 'Heard Island and McDonald Islands',
    'Holy See (Vatican City)': 'Holy See (Vatican City)',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    'Iran, Islamic Republic of': 'Iran, Islamic Republic of',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    'Isle of Man': 'Isle of Man',
    Israel: 'Israel',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    'Korea, Democratic People s Republic of': "Korea, Democratic People's Republic of",
    'Korea, Republic of': 'Korea, Republic of',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'Macedonia, Republic of': 'Macedonia, Republic of',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    'Marshall Islands': 'Marshall Islands',
    Martinique: 'Martinique',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    'Micronesia, Federated States of': 'Micronesia, Federated States of',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    'New Caledonia': 'New Caledonia',
    'New Zealand': 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Norfolk Island',
    'Northern Mariana Islands': 'Northern Mariana Islands',
    Norway: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    'Palestine, State of': 'Palestine, State of',
    Panama: 'Panama',
    'Papua New Guinea': 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Pitcairn: 'Pitcairn',
    Poland: 'Poland',
    Portugal: 'Portugal',
    'Puerto Rico': 'Puerto Rico',
    Qatar: 'Qatar',
    Romania: 'Romania',
    'Russian Federation': 'Russian Federation',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha': 'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Martin': 'Saint Martin',
    'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'San Marino',
    'Sao Tome and Principe': 'Sao Tome and Principe',
    'Saudi Arabia': 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapore',
    'Sint Maarten (Dutch part)': 'Sint Maarten (Dutch part)',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    'Solomon Islands': 'Solomon Islands',
    Somalia: 'Somalia',
    'South Africa': 'South Africa',
    'South Georgia and South Sandwich Islands': 'South Georgia and South Sandwich Islands',
    'South Sudan': 'South Sudan',
    Spain: 'Spain',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    'Syrian Arab Republic': 'Syrian Arab Republic',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    'Tanzania, United Republic of': 'Tanzania, United Republic of',
    Thailand: 'Thailand',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    'Turks and Caicos Islands': 'Turks and Caicos Islands',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'United Arab Emirates',
    'United Kingdom': 'United Kingdom',
    'United States': 'United States',
    'United States Minor Outlying Islands': 'United States Minor Outlying Islands',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    'Venezuela, Bolivarian Republic of': 'Venezuela, Bolivarian Republic of',
    Vietnam: 'Vietnam',
    'Virgin Islands, British': 'Virgin Islands, British',
    'Virgin Islands, US': 'Virgin Islands, U.S.',
    'Wallis and Futuna': 'Wallis and Futuna',
    'Western Sahara': 'Western Sahara',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe',
    'Åland Islands': 'Åland Islands',
  },

  // App translations
  Dashboards: 'Dashboards---',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
}
