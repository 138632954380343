<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <!-- No funciona -->
      <!-- <v-icon size="20">
        {{ icon }}
      </v-icon> -->
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>
        <!-- <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <v-icon v-if="!hideClose">
            {{ icons.mdiClose }}
          </v-icon>
        </span> -->

        <!-- <v-icon size="20">
          {{ icons.mdiRefresh }}
        </v-icon> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiShield } from '@mdi/js'

export default {
  components: {},
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiShield,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .toastification-close-icon,
  .toastification-title {
    line-height: 26px;
  }

  .toastification-title {
    color: inherit;
  }
</style>
