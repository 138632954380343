import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

// SFR Push Notifications
import './registerServiceWorker' // Register SW for push notification

// Plugins (Se registra en App.vue usando provideToast)
// https://github.com/Maronato/vue-toastification/tree/main#composition-api-registration
// Registramos igualmente para usar Vue.$toast.error en apiBase.js
import '@/plugins/toastification'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css' // PerfectScrollbar pluging

// SFR Loadmore plugin
import '@/plugins/loadmore'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
