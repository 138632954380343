<template>
  <!-- style="{ color: blue; z-index: 5; }" -->
  <!-- style="z-index: 2" -->
  <!-- mobile-breakpoint="xs" -->
  <!-- :temporary="$vuetify.breakpoint.xsOnly" -->
  <!-- class="menu-navigation-drawer-user" -->
  <!-- style="max-height: unset;" -->
  <!-- touchless and temporary: Siempre por encima de la pantalla -->
  <!-- z-index por problemas con el buscador -->
  <v-navigation-drawer
    :value="isVerticalMenuActive"
    touchless
    temporary
    :right="true"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '400px'"
    style="z-index: 2; "
    app
    @input="(val) => $emit('update:is-vertical-menu-active', val)"
  >
    <v-card
      class="rounded-0 h-full max-h-content-container--"
    >
      <div class="h-full d-flex flex-column">
        <!-- Header: Navbar -->
        <div
          class="drawer-header d-flex justify-space-between align-center px-2 py-1"
          style="height: 62px;"
        >
          <v-img
            :src="appLogo"
            max-height="48px"
            max-width="48px"
            alt="logo"
            contain
            class="me-3"
          ></v-img>
          <h2 class="font-weight-semibold text-base text--primary">
            {{ appName }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-vertical-menu-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <MenuNavigationDrawerUserItem @close-sidebar="$emit('update:is-vertical-menu-active',false)" />
        <v-divider></v-divider>

        <!-- Content -->
        <!-- style="height: calc(100% - 7.1111rem);" -->
        <div
          class="d-flex flex-column"
        >
          <v-list
            dense
            nav
          >
            <v-list-item
              v-for="item in sidebarItems"
              :key="item.title"
              :to="item.route"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- Botton option -->
          <!-- <div style="margin-top: auto;">
            <v-divider></v-divider>
            <v-list-item
              link
              style="margin-top: auto;"
              @click="onLogout()"
            >
              <v-list-item-icon>
                <v-icon color="error">
                  {{ icons.mdiLogout }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div> -->
        </div>
        <v-divider></v-divider>

        <!-- Footer -->
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <div>
          <v-list-item
            class="navbar fixed-bottom"
            @click="onLogout()"
          >
            <v-list-item-icon>
              <v-icon color="error">
                {{ icons.mdiLogout }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-card>

    <!-- TODO - Usamos flex en vez de v-slot:append (Igual que chat) -->
    <!-- Footer -->
    <!-- <template v-slot:append>
      <v-list-item
        class="navbar fixed-bottom"
        @click="onLogout()"
      >
        <v-list-item-icon>
          <v-icon color="error">
            {{ icons.mdiLogout }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
import {
  mdiClose, mdiLogout, mdiCogOutline, mdiHelpCircleOutline,
} from '@mdi/js'

// UI Core
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'

// Services
import useJwt from '@/services/api/modules/auth/useJwt'

// Components
import MenuNavigationDrawerUserItem from './MenuNavigationDrawerUserItem.vue'

export default {
  components: {
    MenuNavigationDrawerUserItem,
  },
  model: {
    prop: 'isVerticalMenuActive',
    event: 'update:is-vertical-menu-active',
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { router } = useRouter()

    // Company settings TODO - check admin role ???
    const sidebarItems = [
      {
        title: 'Company',
        icon: mdiCogOutline,
        route: { name: 'apps-company-profile' },
      },
      {
        title: 'Help',
        icon: mdiHelpCircleOutline,
        route: { name: 'misc-snippets' },
      },
    ]

    const onLogout = (() => {
      useJwt.logout()
        .then(() => router.push({ name: 'auth-login' }))
    })

    return {
      // App Name
      sidebarItems,
      onLogout,

      icons: {
        mdiClose,
        mdiLogout,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>
<style lang="scss" scoped>

  // .content-layout.vertical-nav {
  .menu-navigation-drawer-user {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    // Assigning 5 z-index: Sino no se puede hacer click (Creo que es porque pertenece al menu)
    z-index: 5;
  }

  .max-h-content-container {
      height: calc((var(--vh, 1vh) * 100)) !important;
  }
</style>
