import axios from '@axios'
import ability from '@/plugins/acl/ability'
import store from '@/store'

// import { initialAbility } from '@/libs/acl/config'
import BaseApiService from '../../apiBase'
import jwtDefaultConfig from './jwtDefaultConfig'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
// const API_BASE_MODULE = process.env.VUE_APP_API_BASE
// const API_BASE_MODULE = 'http://localhost:8060/api'
// const API_BASE_MODULE = '' // @fake-db
export default class JwtService extends BaseApiService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // Overwrite API_BASE
  // API_BASE = API_BASE_MODULE || this.baseUrl
  API_BASE = this.baseUrl

  axiosNoAuth = axios

  constructor() {
    // console.log('CON APIBASE', axios)
    super(axios)
  }

  accessLogin(...args) {
    // return this.axiosIns.post(API_BASE + this.jwtConfig.loginEndpoint, ...args)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(this.API_BASE + this.jwtConfig.loginEndpoint, ...args, { noAuthorization: true })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            // >>> login actions
            this.setToken(response.data.accessToken)
            ability.update(userData.ability)
            store.commit('auth/SUCCESS', response.data)
            store.dispatch('user/SET', userData)

            // <<< login actions

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          this.setToken(null)
          reject(error)
        })
    })
  }

  // Ver apiBase.js ==> Lo hemos pasado para allí por el auto-login
  // Igual que el LOGIN
  //   accessToken() {
  //     return new Promise((resolve, reject) => {
  //       this.axiosIns
  //         .post(this.API_BASE + this.jwtConfig.refreshEndpoint, {
  //           accessToken: this.getToken(),
  //         }, { noAuthorization: true })
  //         .then(response => {
  //           const { userData } = response.data
  //           if (userData) {
  //             this.setToken(response.data.accessToken)

  //             ability.update(userData.ability)
  //             store.commit('auth/SUCCESS', response.data)
  //             store.commit('user/SET', userData)

  //             resolve(response)
  //           }
  //           reject(response)
  //         })
  //         .catch(error => {
  //           reject(error)
  //         })
  //     })
  //   }

  // Ver apiBase.js ==> Lo hemos pasado para allí por el auto-logout
  //   logout() {
  //     // return this.axiosIns.post(API_BASE + this.jwtConfig.logoutEndpoint)
  //     return new Promise(resolve => {
  //       this.setToken(null)

  //       ability.update(initialAbility) // Permisos por defecto
  //       store.commit('auth/LOGOUT')
  //       store.commit('user/SET', null)

  //       resolve()
  //     })
  //   }

  // Igual que el LOGIN (Eso porque lo metemos dentro de la app)
  register(...args) {
    // return this.axiosIns.put(API_BASE + this.jwtConfig.registerEndpoint, ...args)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(this.API_BASE + this.jwtConfig.registerEndpoint, ...args, { noAuthorization: true })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            this.setToken(response.data.accessToken)

            ability.update(userData.ability)
            store.commit('auth/SUCCESS', response.data)
            store.dispatch('user/SET', userData)

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Igual que el LOGIN (Eso porque lo metemos dentro de la app)
  registerByWizard(...args) {
    // eslint-disable-next-line class-methods-use-this
    // return new Promise(resolve => resolve(args))
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(this.API_BASE + this.jwtConfig.registerWizardEndpoint, ...args, { noAuthorization: true })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            // >>> login actions
            // this.setToken(response.data.accessToken)
            // ability.update(userData.ability)
            // store.commit('auth/SUCCESS', response.data)
            // store.dispatch('user/SET', userData)
            // <<< login actions

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // AutoLogin
  // [access_control] companyRole: IS_AUTHENTICATED_ANONYMOUSLY
  // args = { accessToken } => { userData }
  registerValidation(...args) {
    // this.accessToken()
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(this.API_BASE + this.jwtConfig.refreshEndpoint, ...args, { noAuthorization: true })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            // >>> login actions
            this.setToken(response.data.accessToken)
            ability.update(userData.ability)
            store.commit('auth/SUCCESS', response.data)
            store.dispatch('user/SET', userData)

            // <<< login actions

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  update(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(this.API_BASE + this.jwtConfig.updateEndpoint, ...args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Solicitar RESET PASSWORD EXTERNO (El usuario olvidó la contraseña)
  // [access_control] companyRole: IS_AUTHENTICATED_ANONYMOUSLY
  // args = { username } => { status, message }
  resetPasswordRequest(...args) {
    return this.axiosIns.post(this.API_BASE + this.jwtConfig.resetPasswordRequest, ...args, { noAuthorization: true })
  }

  // Login pero sin cambiar el estado
  // [access_control] companyRole: IS_AUTHENTICATED_ANONYMOUSLY
  // args = { accessToken } => { userData }
  resetPasswordValidation(...args) {
    // return this.axiosIns.post(API_BASE + this.jwtConfig.resetPasswordValidation, ...args)
    return this.axiosIns.post(this.API_BASE + this.jwtConfig.refreshEndpoint, ...args, { noAuthorization: true })
  }

  // Igual que el LOGIN [access_control] companyRole: IS_AUTHENTICATED_ANONYMOUSLY
  // args = { accessToken, password } => { userData, accessToken, refreshToken }
  resetPassword(...args) {
    // return this.axiosIns.post(this.jwtConfig.resetPassword, ...args)
    return new Promise((resolve, reject) => {
      // Request sin autentificación: El token autentifica dentro del request
      //   const headers = {}
      //   const { accessToken } = args
      //   if (accessToken) {
      //     headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
      //   }

      this.axiosIns
        .post(this.API_BASE + this.jwtConfig.resetPassword, ...args, { noAuthorization: true })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            // >>> login actions
            this.setToken(response.data.accessToken)
            ability.update(userData.ability)
            store.commit('auth/SUCCESS', response.data)
            store.dispatch('user/SET', userData)

            // <<< login actions

            resolve(response)
          }
          reject(response)
        })
        .catch(error => {
          this.setToken(null)
          reject(error)
        })
    })
  }
}
