// Marketing: /marketing/campaign         => /marketing/campaign
// Marketing: /marketing/campaign/inbound => /marketing/campaign-inbound
export default [

  // Marketing >>> Campaign [S]
  // {
  //   path: '/marketing/campaign',
  //   name: 'apps-campaign',
  //   component: () => import('@/views/apps/campaign/campaign/Campaign.vue'),
  //   meta: {
  //     layout: 'content',
  //     navActiveLink: 'marketing',
  //   },
  // },
  {
    path: '/marketing/campaign/:folder',
    name: 'apps-campaign-folder',
    component: () => import('@/views/apps/campaign/campaign/Campaign.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketing',
    },
    beforeEnter(to, _, next) {
      if (['published', 'draft', 'finished', 'starred', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/marketing/campaign/:label',
    name: 'apps-campaign-label',
    component: () => import('@/views/apps/campaign/campaign/Campaign.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketing',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // Network-P >>> Campaign Inbound [P]
  // /marketing/campaign-inbound ==> /network/campaign-inbound (Evitar ir a CAMPAIGN)
  // {
  //   path: '/network/campaign-inbound',
  //   name: 'apps-campaign-inbound',
  //   component: () => import('@/views/apps/campaign/campaign-inbound/CampaignInbound.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/marketing/campaign-inbound/:folder',
    name: 'apps-campaign-inbound-folder',
    component: () => import('@/views/apps/campaign/campaign-inbound/CampaignInbound.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-campaign-inbound',
    },
    beforeEnter(to, _, next) {
      if (['received', 'accepted', 'rejected', 'starred', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/marketing/campaign-inbound/:label',
    name: 'apps-campaign-inbound-label',
    component: () => import('@/views/apps/campaign/campaign-inbound/CampaignInbound.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-campaign-inbound',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]
