<template>
  <div>
    <!-- brand logo -->
    <router-link
      to="/"
      class="brand-logo d-flex align-center justify-center"
      style="text-decoration: unset;"
    >
      <v-img
        :src="appLogo"
        max-height="48px"
        max-width="48px"
        alt="logo"
        contain
        class="me-3"
      ></v-img>

      <h2 class="text--primary">
        {{ appName }}
      </h2>
    </router-link>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'

export default {
  setup() {
    return {

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>
