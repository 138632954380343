<template>
  <div
    class="navbar-content-container"
    :class="{'expanded-search': false}"
  >
    <!-- Left Content: Search -->
    <div class="d-flex align-center">
      <AppBarLogo />
    </div>

    <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
    <div class="d-flex align-center right-row">
      <AppBarI18n />
      <AppBarThemeSwitcher class="mx-4"></AppBarThemeSwitcher>
    </div>
  </div>
</template>

<script>
import { mdiMenu, mdiHeartOutline } from '@mdi/js'

// App Components
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarLogo from '@/components/AppBar/AppBarLogo.vue'
import AppBarI18n from '@/components/AppBar/AppBarI18n.vue'

export default {
  components: {
    AppBarLogo,
    AppBarI18n,
    AppBarThemeSwitcher,
  },

  setup() {
    return {
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .navbar-content-container {
    height: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    display: flex;
    // flex-grow: 1;
    flex: 1;
  }
  // Quitamos el padding del v-toolbar
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding: 0
    }
  }
</style>
