export default [

  // Company Profile Management
  {
    path: '/company',
    name: 'apps-company-profile',

    // component: () => import('@/views/apps/company/CompanyProfile.vue'),
    component: () => import('@/views/apps/company/company-settings/CompanySettings.vue'),
    meta: {
      layout: 'content',
    },
  },
]
