export default [

  // // Chat
  // {
  //   path: '/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },

  // Pricing page
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pages/Pricing.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  // MISCELANEOUS
  // Redirigimos cuando no tenga permisos para el recurso solicitado (Ver Router Before Each hook)
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  // HELP
  // Redirigimos cuando no tenga permisos para el recurso solicitado (Ver Router Before Each hook)
  {
    path: '/pages/miscellaneous/snippets',
    name: 'misc-snippets',
    component: () => import('@/views/pages/miscellaneous/SnippetGrid.vue'),
    meta: {
      layout: 'content',
    },
  },

  // Sin uso por el momento
  // {
  //   path: '/pages/miscellaneous/error',
  //   name: 'misc-error',
  //   component: () => import('@/views/pages/miscellaneous/Error.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },

  // {
  //   path: '/pages/miscellaneous/under-maintenance',
  //   name: 'misc-under-maintenance',
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },

  // {
  //   path: '/pages/miscellaneous/coming-soon',
  //   name: 'misc-coming-soon',
  //   component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },

  // {
  //   path: '/pages/account-setting',
  //   name: 'pages-account-setting',
  //   component: () => import('@/views/pages/account-setting/AccountSetting.vue'),

  //   // component: () => import('@/views/pages/account-setting/Account.vue'),
  //   meta: {
  //   },
  // },

  // // Company...
  // {
  //   path: '/pages/company-setting',
  //   name: 'pages-company-setting',
  //   component: () => import('@/views/pages/company/company-setting/CompanySetting.vue'),
  //   meta: {
  //   },
  // },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/pages/profile/Profile.vue'),
  //   meta: {
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/pages/faq',
  //   name: 'pages-faq',
  //   component: () => import('@/views/pages/faq/Faq.vue'),
  //   meta: {
  //     pageTitle: 'FAQ',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'FAQ',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/knowledge-base',
  //   name: 'pages-knowledge-base',
  //   component: () => import('@/views/pages/Knowledge-base/KnowledgeBase.vue'),
  //   meta: {
  //     pageTitle: 'Knowledge Base',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Knowledge Base',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/knowledge-base/:category',
  //   name: 'pages-knowledge-base-category',
  //   component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue'),
  //   meta: {
  //     pageTitle: 'Category',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Knowledge Base',
  //         to: '/pages/Knowledge-base',
  //       },
  //       {
  //         text: 'Category',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'pages-knowledge-base',
  //   },
  // },
  // {
  //   path: '/pages/knowledge-base/:category/:slug',
  //   name: 'pages-knowledge-base-question',
  //   component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
  //   meta: {
  //     pageTitle: 'Question',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Knowledge Base',
  //         to: '/pages/Knowledge-base',
  //       },
  //       {
  //         text: 'Category',
  //         to: '/pages/Knowledge-base/category',
  //       },
  //       {
  //         text: 'Question',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'pages-knowledge-base',
  //   },
  // },
  // {
  //   path: '/pages/pricing',
  //   name: 'pages-pricing',
  //   component: () => import('@/views/pages/pricing/Pricing.vue'),
  // },
]
