<template>
  <!-- <b-nav-item @click="appMode = isSeller ? 'purchaser' : 'seller'"> -->
  <!-- :disabled="!(isAppModeSeller && isAppModePurchaser)" -->
  <!-- @click="$emit('close-sidebar', true)" -->
  <div
    @click="onToggleAppMode()"
  >
    <!-- Option 2 -->
    <!-- isSeller:  // Seller: Primary #1c6bd1; Purchaser: Success #28c76f -->
    <!-- :badge-variant="isSeller ? 'primary' : 'success'" -->
    <!-- :variant="isSeller ? 'light-primary' : 'light-success'" -->
    <v-badge
      v-if="isProfileToggleable"
      left
      :color="appModeVariant"
      overlap
      offset-x="42"
      offset-y="12"
      class="ms-4"
      :content="isSeller ? 'Seller' : 'Purch.'"
    >
      <!-- Opcion1: Swicher Es más intuitivo pero no funciona con computadas -->
      <!-- Falla porque isSeller es una computada y es readOnly -->
      <!-- <v-switch
        v-model="isSeller"
        :color="appModeVariant"
        hide-details
        @click="appMode = (appMode === 'seller' ? 'purchaser' : 'seller')"
      ></v-switch> -->

      <!-- Opcion2: -->
      <!-- @click="appMode = (appMode === 'seller' ? 'purchaser' : 'seller')" -->
      <!-- {{ isSeller ? icons.mdiToggleSwitchOutline : icons.mdiToggleSwitchOffOutline }} -->
      <v-icon
        :key="appMode"
        size="28"
        class="mt-4 pt-1"
      >
        {{ appMode === 'seller' ? icons.mdiBasketOutline : icons.mdiShopping }}
      </v-icon>
    </v-badge>
    <v-avatar
      v-else
      size="40"
      :class="`v-avatar-light-bg ${appModeVariant}--text`"
    >
      <span class="text-h5">{{ isSeller ? 'S' : 'P' }}</span>
    </v-avatar>
  </div>
</template>

<script>
import {
  mdiToggleSwitchOutline, mdiToggleSwitchOffOutline, mdiBasketOutline, mdiShopping,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

// Theme
// import themeConfig from '@themeConfig'
// import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'

import store from '@/store'

// Services
import useAuthHandler from '@/views/pages/auth/useAuthHandler'

// UI Services Custom
import useAppConfigUser from '@/services/app/useAppConfigUser'

export default {
  setup(_, { emit }) {
    const { router } = useRouter()
    const { sendAuthNotification } = useAuthHandler()

    // const { themes } = useAppConfig()
    // appMode [seller/purchaser]
    const {
      appMode, isSeller, appModeVariant, appModeVariantLight,
    } = useAppConfigUser()

    // const isSeller = computed(() => appMode.value === 'seller')

    // Seller (User has profile seller ???)
    const userProfileSellerActive = ref(store.state.user.seller)
    watch(() => store.state.user.seller, () => {
      userProfileSellerActive.value = store.state.user.seller
    })

    // Purchaser (User has profile purchaser ???)
    const userProfilePurchaserActive = ref(store.state.user.purchaser)
    watch(() => store.state.user.purchaser, () => {
      userProfilePurchaserActive.value = store.state.user.purchaser
    })

    // Is Toggleable => El usuario tiene los 2 perfiles (Se permite cambiar)
    const isProfileToggleable = computed(() => userProfileSellerActive.value && userProfilePurchaserActive.value)

    // Checking... RoutedName in currentRoute ???
    // const isCurrentRouteMatch = resolveRoutedName => {
    //   const matchedRoutes = router.currentRoute.matched // Matched routes array of current route
    //   return matchedRoutes.some(route => route.name === resolveRoutedName || route.meta.navActiveLink === resolveRoutedName)
    // }
    // Checking... [RoutedName] in currentRoute ???
    const isCurrentRouteinList = routedNameList => {
      const matchedRoutes = router.currentRoute.matched // Matched routes array of current route

      return routedNameList.find(routedName => matchedRoutes.some(route => route.name === routedName || route.meta.navActiveLink === routedName))
    }

    // eslint-disable-next-line no-unused-vars
    const goToBestRoute = newAppMode => {
      let goToPath = null // { path: '/' }

      // todo - Leer la lista de rutas de useApp (Cuando se cree en navBarH)
      const sellerPaths = ['apps-product-supplier-list', 'apps-campaign', 'apps-contact-request-inbound']
      const purchaserPaths = ['apps-product-demander-list', 'apps-marketplace-products', 'apps-marketplace-checkout', 'apps-contact-request', 'apps-campaign-inbound']

      // Checking...
      //  Seller    => Estamos en algun path de Purchaser ???
      //  Purchaser => Estamos en algun path de Seller ???
      if (newAppMode === 'seller') {
        if (isCurrentRouteinList(purchaserPaths)) { goToPath = { name: 'home' } }
      }
      if (newAppMode === 'purchaser') {
        // const find = sellerPaths.find(item => isCurrentRouteMatch(item))
        // if (find) {
        //   goToPath = { name: 'dashboard-analytics' }
        // }
        if (isCurrentRouteinList(sellerPaths)) { goToPath = { name: 'home' } }
      }

      // router.go({ path: '/' })
      // router.push({ name: 'auth-login' })
      // router.replace({ path: '/' })
      if (goToPath) {
        router.replace(goToPath)

          // .then()
          // .catch(error => {
          //   console.log(error.message) // Redundant
          //   router.go({ path: '/' }) // Force all app (Reload by token)
          // })
          .finally() // Execute without check
      }
    }

    // Toggle AppMode
    const onToggleAppMode = () => {
      // Checking... Tiene los 2 perfiles --> Switch
      if (isProfileToggleable.value) {
        // AppMode Swicth: LocalStorage + Store
        appMode.value = isSeller.value ? 'purchaser' : 'seller' // Invert seller vs purchaser

        // Save at LocalStorage
        // localStorage.setItem('app-mode', appMode.value)

        // Emit event and notify
        emit('close-sidebar', true)
        sendAuthNotification(`Load ${appMode.value} profile`, 'success')

        // Change Theme color
        // const color = `${appMode.value ? '#1c6bd1' : '#28c76f'}`
        // const color = `${appMode.value === 'seller' ? 'blue' : 'green'}`
        // document.querySelector('meta[name="theme-color"]').setAttribute('content', color)
        // themes.value = appMode.value === 'seller' ? themeConfig.themeVariants.seller : themeConfig.themeVariants.purchaser
      }

      // Go to best route
      goToBestRoute(appMode.value)
    }

    return {
      // AppMode
      appMode,
      isSeller,
      appModeVariant,
      appModeVariantLight,

      isProfileToggleable,
      onToggleAppMode,

      icons: {
        mdiToggleSwitchOutline,
        mdiToggleSwitchOffOutline,
        mdiBasketOutline,
        mdiShopping,
      },
    }
  },
}
</script>
