import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { canNavigate } from '@/plugins/acl/routeProtection'

import auth from './auth'
import pages from './pages'

// Auth
import useJwt from '@/services/api/modules/auth/useJwt'

// Modulos
import appCompany from './modules/company'
import appUser from './modules/user'

// Modulos
import appCampaign from './modules/campaign'
import appChat from './modules/chat'
import appContactRequest from './modules/contact-request'
import appMarketplace from './modules/marketplace'
import appProduct from './modules/product'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.companyRole ? userData.role : null

      if (userRole === 'admin') return { name: 'home-page' }
      if (userRole === 'client') return { name: 'home-page' }

      return { name: 'auth-login', query: to.query }

      // console.log(to)
      // return { name: 'home' }
    },
  },

  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/apps/home/HomePage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  ...auth,
  ...pages,

  ...appUser,
  ...appCompany,
  ...appProduct,
  ...appMarketplace,
  ...appContactRequest,
  ...appCampaign,
  ...appChat,

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  // SFR Is login and has token
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const hasTokenStored = localStorage.getItem('accessToken')

  if (!canNavigate(to)) {
    // Autologin: Cuando refrescamos pantalla o introducimos url manualmente
    if (!isLoggedIn) {
      // Si tiene token, intentamos hacer Auto-login
      if (hasTokenStored) {
        const logedIn = await useJwt.accessToken() // LogedIn ?
        if (logedIn) {
          return next(to) // Volvemos a llamar a la misma ruta => Se vuelve a comprobar si hay acceso a la ruta
        }
      }

      // Redirect to login if not logged in
      // return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
