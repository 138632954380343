export default {
  namespaced: true,
  state: {
    // companyData: null,
    // AppMode permited modes (Company level)
    seller: false,
    purchaser: false,
  },

  //   getters: {
  //     isAppModeSeller: state => state.seller === true,
  //     isAppModePurchaser: state => state.purchaser === true,
  //   },

  // store.commit('company/SET', payload)
  mutations: {
    // Por ahora metemos la info de company dentro del usuario
    // Ejecutamos al login usuario: commit('company/SET', payload.company, { root: true })
    SET(state, companyData) {
      // state.companyData = companyData
      state.seller = false
      state.purchaser = false

      if (companyData && companyData.general) {
        state.seller = companyData.general?.seller || false
        state.purchaser = companyData.general?.purchaser || false
      }
    },
  },
  actions: {
    // setCompany({ commit, rootState }, companyData) {
    setCompany({ commit }, companyData) {
      commit('SET', companyData)
    },
  },
}
