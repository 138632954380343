<template>
  <div>
    <!-- Sidebar: Personal Info -->
    <div
      v-if="userData"
      class="d-flex align-center justify-space-between px-2 py-1"
      style="height: 62px;"
    >
      <!-- Flex Row: [User] [Options] -->

      <!-- Flex Row: [User] => Avatar + [Name + Username] -->
      <router-link
        :to="{ name: 'apps-user-profile' }"
        style="color: unset; text-decoration: unset;"
        class="d-flex align-center justify-space-between"
      >
        <div class="">
          <!-- isSeller:  // Seller: Primary #1c6bd1; Purchaser: Success #28c76f -->
          <!-- :variant="isSeller ? 'light-primary' : 'light-success'" -->
          <!-- :style="{ border: `2px solid ${isSeller ? '#1c6bd1' : '#28c76f'}`}" -->
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.avatar"
              :src="userData.avatar"
            ></v-img>
            <v-icon
              v-if="!userData.avatar"
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </div>

        <!-- Flex Column: [Name + Username] -->
        <!-- Indicamos un ancho para que no se vaya fuera -->
        <!-- style="width: 120px;" -->
        <div
          class="d-flex flex-column ml-1"
        >
          <p class="font-weight-bolder text-truncate text-h6 mb-0">
            {{ getUserfullName }}
          </p>
          <p class="text-truncate text-subtitle-1 mb-0">
            {{ userData.username }}
            <v-icon v-if="userData.companyRole === 'admin'">
              {{ icons.mdiAccountKey }}
            </v-icon>
          </p>
        </div>
        <v-icon
          class="align-text-bottom"
          size="22"
        >
          {{ icons.mdiCogOutline }}
        </v-icon>
      </router-link>

      <!-- Fox row: [Options] => [SettingsIcon][SPSwitchIcon] -->
      <!-- <div class="d-flex align-center justify-space-between"> -->
      <MenuNavigationDrawerSwitchRole
        class="mr-2"
        @close-sidebar="$emit('close-sidebar', true)"
      />
      <!-- <MenuNavigationThemeSwitcher
          class="ml-1"
          @close-sidebar="$emit('close-sidebar', true)"
        /> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mdiAccountKey, mdiAccountOutline, mdiCogOutline } from '@mdi/js'

import { computed } from '@vue/composition-api'

// Components
// import MenuNavigationThemeSwitcher from './MenuNavigationDrawerSwithRoleIcon.vue'
import MenuNavigationDrawerSwitchRole from './MenuNavigationDrawerSwitchRole.vue'

// UI Services Custom
import useAppConfigUser from '@/services/app/useAppConfigUser'

export default {
  components: {
    // MenuNavigationThemeSwitcher,
    MenuNavigationDrawerSwitchRole,
  },
  setup() {
    // Usamos Watch para activar reactividad ante cambios de estado
    //  userData
    //  appMode [seller/purchaser]
    const { userData } = useAppConfigUser()
    const getUserfullName = computed(() => `${userData.value.firstName || ''} ${userData.value.lastName || ''}`)

    // const { resolveAvatarBadgeVariant } = useChat()

    return {
      userData,
      getUserfullName,

      icons: {
        mdiCogOutline,
        mdiAccountOutline,
        mdiAccountKey,
      },
    }
  },
}
</script>

<style scoped>
</style>
