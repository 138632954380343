import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: {
    status: '',
    token: '',
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    isAuthenticated: state => (state.token !== null) && (state.exp >= (new Date().getTime() / 1000)),
  },
  mutations: {
    REQUEST(state) {
      state.status = 'loading'
    },
    SUCCESS(state, payload) {
      state.status = 'success'
      state.token = payload.accessToken

      // jwt info (Not used by de moment)
      const payloadToken = jwtDecode(payload.accessToken)
      state.id = payloadToken.id
      state.exp = payloadToken.exp
      state.username = payloadToken.username
      state.iat = payloadToken.iat
      state.roles = payloadToken.roles // not inclued: We use ability
    },
    ERROR(state) {
      state.status = 'error'
    },
    LOGOUT(state) {
      state.status = ''
      state.token = ''

    //   state.userData = null
    },
  },
  actions: {},
}
