<template>
  <layout-blank>
    <slot></slot>
    <template #navbar>
      <AppNavBar />
    </template>
    <!-- <template #navbarTab>
      <AppBarMenu
        :items="navbarHItems"
        :active-color="appTheme.primary"
      />
    </template> -->
    <!-- <<< SFR Custom Navbar -->
  </layout-blank>
</template>

<script>

// Components
// import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlankCopy.vue'
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutNoAuth.vue'

// Components
import AppNavBar from '@/components/AppNavBar/AppNavBar.vue'

export default {
  components: {
    LayoutBlank,
    AppNavBar,
  },
}
</script>
