<template>
  <v-btn
    v-if="userData"
    text
    right
    class="px-0"

    @click="toggleVerticalMenuSidebarActive"
  >
    <!-- <div class="d-flex flex-column align-center"> -->
    <div class="navbarH-flex">
      <v-avatar
        size="40px"
        color="primary"
        class="v-avatar-light-bg primary--text"
      >
        <v-img
          v-if="userData.avatar"
          :src="userData.avatar"
        ></v-img>
        <v-icon
          v-if="!userData.avatar"
          color="primary"
          size="28"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </v-avatar>

      <div class="d-sm-flex d-none">
        <p class="mb-0 mr-2">
          {{ getUserfullName }}
        </p>
      </div>
    </div>
  </v-btn>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'

// import store from '@/store'
import { computed } from '@vue/composition-api'

// UI Services Core
// import { initialAbility } from '@/libs/acl/config'
// import useAppConfig from '@core/app-config/useAppConfig'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import useJwt from '@/services/api/modules/auth/useJwt'

// UI Services Custom
import useAppConfigUser from '@/services/app/useAppConfigUser'

// import useChat from '@/views/apps/chat/useChatHandler'

export default {
  props: {
    toggleVerticalMenuSidebarActive: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  setup() {
    const { router } = useRouter()

    // const { resolveAvatarBadgeVariant } = useChat()

    // AppConfig and user data
    //  userData
    //  appTheme [seller/purchaser]
    const { userData, appTheme } = useAppConfigUser()

    // Esta computada no actualiza sin el watch
    const getUserfullName = computed(() => (
      `${userData.value.firstName || ''} ${userData.value.lastName || ''}`
    ))

    const onLogout = (() => {
      useJwt.logout()
        .then(() => router.push({ name: 'auth-login' }))
    })

    return {
      userData,
      getUserfullName,

      // resolveAvatarBadgeVariant,
      avatarText,
      onLogout,

      // AppMode
      appTheme,

      icons: {
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .navbarH-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
