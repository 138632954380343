// Network:     /marketing/product/supplier   => /marketing/product-supplier
// Marketplace: /marketplace/product/demander => /marketing/product-demander
export default [

  // Marketing >>> Catalog [S]
  {
    path: '/marketing/product-supplier/list',
    name: 'apps-product-supplier-list',
    component: () => import('@/views/apps/product/product-supplier/product-supplier-list/ProductSupplierList.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketing',
    },
  },

  // Marketplace >>> Interes list [P]
  {
    path: '/marketplace/product-demander/list',
    name: 'apps-product-demander-list',
    component: () => import('@/views/apps/product/product-demander/product-demander-list/ProductDemanderList.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'marketplace',
    },
  },

]
